/* eslint-disable no-useless-escape */
import React, { Fragment } from 'react'
import replace from 'react-string-replace'
import places from '../data/places'
import { translationsMapping } from '../../config'
import Link from '../components/router/Link'
import DeckGuideCardLink from '../pages/deck/components/DeckGuideCardLink'
import unique from './unique'
import Checkbox from '../components/form/Checkbox'
import countries from '../data/countries'

export const slugify = text => (
	text.toString().toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w\-]+/g, '')
		.replace(/\-\-+/g, '-')
		.replace(/^-+/, '')
		.replace(/-+$/, '')
)

export const slugifyUsername = username => username.toString().toLowerCase()

export const normalize = str => str ? str.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, '') : str

export const capitalize = str => str ? str.charAt(0).toUpperCase() + str.slice(1) : str;

export const fillZeroes = (num, places) => String(num).padStart(places, '0')

export const countWords = str => str.length > 0 ? str.trim().split(/\s+/).length : 0

export const isNumeric = (str) => {
	if (typeof str !== 'string') return false
	return !isNaN(str) && !isNaN(parseFloat(str))
}

export const isLink = (str) => {
	if (/\r|\n/.exec(str)) {
		return false
	}

	let url

	try {
		url = new URL(str)
	} catch (_) {
		return false
	}

	return url.protocol === 'http:' || url.protocol === 'https:'
}

export const encodeSearchString = (q) => {
	if (!q && q === '') {
		return null
	}

	const encoded = encodeURIComponent(q.toString().trim())

	if (!encoded) {
		return null
	}

	return encoded.replace(/%20/g, '+')
}

export const decodeSearchString = q => q ? decodeURIComponent(q.toString().trim().replace(/\+/g, '%20')) : null

export const toFormData = (obj) => {
	const formData = new FormData()

	Object.entries(obj).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			value.forEach((item) => {
				formData.append(`${key}[]`, getFormDataValue(item))
			})
		} else {
			formData.append(key, getFormDataValue(value))
		}
	})

	return formData
}

const getFormDataValue = (value) => {
	if (value === true) {
		return '1'
	}

	if (value === false) {
		return '0'
	}

	if (value === null || value === undefined) {
		return ''
	}

	return value
}

export const formatDeckGuide = (text, deck, cardNameLanguage) => {
	let result = text

	result = addLinks(result, deck.visibility !== 'private')
	result = formatLists(result, deck, cardNameLanguage)
	result = formatMarkdown(result, deck, cardNameLanguage)
	result = addCardLinks(result, deck, cardNameLanguage)

	return result
}

export const formatDeckComment = (text, deck, usernames, cardNameLanguage) => {
	let result = text

	result = addLinks(result)
	result = addCardLinks(result, deck, cardNameLanguage)
	result = addUserLinks(result, usernames)

	return result
}

export const addLinks = (text, restrict = true) => {
	const urlRegex = /(\bhttps:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig

	const internal = [
		'https://cardcluster.com',
		'https://cardcluster.de',
		'https://cardcluster.fr',
		'https://cardcluster.it',
		'https://cardcluster.es',
		'https://cardcluster.pt'
	]

	const whiteList = [
		'https://youtube.com',
		'https://youtu.be',
		'https://twitch.tv',
		'https://twitter.com',
		'https://roadoftheking.com',
		'https://ygorganization.com',
		'https://reddit.com'
	]

	return replace(text, urlRegex, (match) => {
		const internalDomain = internal.find(item => match.startsWith(item))

		if (internalDomain) {
			return (
				<Link to={match.replace(internalDomain, '')} key={match}>{match}</Link>
			)
		}

		if (restrict && !whiteList.find(item => match.startsWith(item) || match.replace('https://www.', 'https://').startsWith(item))) {
			return <Fragment key={match}>{match}</Fragment>
		}

		return (
			<Link external to={match} key={match}>{match}</Link>
		)
	})
}

export const addCardLinks = (text, deck, cardNameLanguage) => {
	let result = text

	const replacements = deck.cards.reduce((accumulator, current) => {
		let keywords = [
			current.card.name_en,
			current.card[`name_${cardNameLanguage}`] || current.card.name
		]

		if (current.card.synonyms && current.card.synonyms.length > 0) {
			keywords = [
				...keywords,
				...(current.card.synonyms || [])
			]
		}

		keywords.forEach((keyword) => {
			if (!accumulator[keyword]) {
				accumulator[keyword] = (
					<DeckGuideCardLink
						deckCard={current}
						text={keyword}
					/>
				)
			}
		})

		return accumulator
	}, {})

	Object.entries(replacements)
		.sort(([a], [b]) => b.length - a.length)
		.forEach(([key, value]) => {
			//result = replace(result, new RegExp(`\b(${key})\b`, 'i'), match => <Fragment key={unique()}>{match}</Fragment>)
			result = replace(result, new RegExp(`( |^)"?${key}"?`, 'gmi'), () => <Fragment key={unique()}> {value}</Fragment>)
		})

	result = replace(result, /@([a-zßäöü \d_-]+)/ig, (query) => {
		const deckCard = deck.cards.find((item) => {
			const cardName = item.card[`name_${cardNameLanguage}`] || item.card.name_en || item.card.name

			return !!(cardName.toLowerCase().includes(query.toLowerCase()) || item.card.name_en?.toLowerCase().includes(query.toLowerCase()))
		})

		if (deckCard) {
			return (
				<DeckGuideCardLink
					deckCard={deckCard}
					text={query}
					key={unique()}
				/>
			)
		}

		return `@${query}`
	})

	return result
}

export const addUserLinks = (text, usernames) => {
	let result = text

	result = replace(result, /@([a-zßäöü\d_-]+)/ig, (query) => {
		const username = usernames.find(item => item.toLowerCase() === query.toLowerCase())

		if (username) {
			return (
				<Link to={`/user/${slugifyUsername(username)}`}>
					@{username}
				</Link>
			)
		}

		return `@${query}`
	})

	return result
}

export const formatLists = (text, deck, cardNameLanguage) => {
	let result = text

	result = replace(result, /^# (.*)/gm, (match, i) => (
		<div className="h1" key={match + i}>{match}</div>
	))
	result = replace(result, /^[-*] \[x](.*)/gm, (match, i) => (
		<li className="checkbox" key={match + i}>
			<Checkbox
				label={formatMarkdown(match, deck, cardNameLanguage)}
				value={true}
				onChange={() => {}}
			/>
		</li>
	))
	result = replace(result, /^[-*] \[](.*)/gm, (match, i) => (
		<li className="checkbox" key={match + i}>
			<Checkbox
				label={formatMarkdown(match, deck, cardNameLanguage)}
				value={false}
				onChange={() => {}}
			/>
		</li>
	))
	result = replace(result, /^[-*] (.*)/gm, (match, i) => (
		<li key={match + i}>{match}</li>
	))
	result = replace(result, /^[0-9]+\. (.*)/gm, (match, i) => (
		<li className="decimal" key={match + i}>{match}</li>
	))

	// add ul, ol
	const result2 = []
	let listItems = []
	let dangling = null

	result.forEach((el) => {
		if (el.type === 'li') {
			listItems.push(el)
		} else if (listItems.length > 0) {
			if (typeof el === 'string' && el.match(/^[\n]{1,}.+/)) {
				dangling = el.replace(/^\n\n/, '')
			} else if (el === '\n') {
				return
			}

			if (listItems[0].props.className === 'decimal') {
				result2.push((
					<ol key={unique()}>
						{listItems.map((item, i) => (
							<li key={item + i}>
								{formatMarkdown(item.props.children, deck, cardNameLanguage)}
							</li>
						))}
					</ol>
				))
			} else {
				result2.push((
					<ul key={unique()}>
						{listItems.map((item, i) => (
							<li className={item.props.className} key={item + i}>
								{formatMarkdown(item.props.children, deck, cardNameLanguage)}
							</li>
						))}
					</ul>
				))
			}

			listItems = []
		} else {
			result2.push(el)
		}

		if (dangling) {
			result2.push(typeof dangling === 'string' ? dangling.trim() : dangling)
			dangling = null
		}
	})

	return result2
}

export const formatMarkdown = (text, deck, cardNameLanguage) => {
	let result = text

	result = addCardLinks(result, deck, cardNameLanguage)

	result = replace(result, /\*\*(.*?)\*\*/g, (match, i) => <b key={match + i}>{match}</b>)
	result = replace(result, /\*(.*?)\*/g, (match, i) => <i key={match + i}>{match}</i>)
	result = replace(result, /~~(.*?)~~/g, (match, i) => <strike key={match + i}>{match}</strike>)

	return result
}

export const formatCurrency = (currency, amount, attachCurrency = true, attachRaw = false, obscure = false) => {
	const commaCurrencies = ['EUR', 'NOK']
	const decimalSeparator = commaCurrencies.find(item => item === currency) ? ',' : '.'

	let str = (amount / 100).toString()

	if (currency === 'JPY' && amount) {
		str = amount.toString()
	}

	if (str === '0' && currency !== 'JPY') {
		str = '0.00'
	}

	if (decimalSeparator === ',') {
		str = str.replace('.', ',')
	}

	const split = str.split(decimalSeparator)

	if (!split[1] && currency !== 'JPY') {
		str += `${decimalSeparator}00`
	} else if (split[1] && split[1].length === 1) {
		str += '0'
	}

	str = str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, decimalSeparator === ',' ? '.' : ',')

	if (obscure) {
		str = str.replace(/[0-9]/g, '?')
	}

	if (attachCurrency) {
		if (currency === 'EUR') {
			if (attachRaw) {
				str = `${str} €`
			} else {
				str = <>{str}&nbsp;<span>€</span></>
			}
		} else if (currency === 'CHF') {
			if (attachRaw) {
				str = `Fr. ${str}`
			} else {
				str = <><span>Fr.</span>&nbsp;{str}</>
			}
		} else if (currency === 'NOK') {
			if (attachRaw) {
				str = `kr${str}`
			} else {
				str = <><span>kr</span>{str}</>
			}
		} else if (currency === 'USD' || currency === 'AUD' || currency === 'NZD') {
			if (attachRaw) {
				str = `$${str}`
			} else {
				str = <><span>$</span>{str}</>
			}
		} else if (currency === 'JPY') {
			if (attachRaw) {
				str = `￥${str}`
			} else {
				str = <><span>￥</span>{str}</>
			}
		}
	}

	return str
}

export const formatInterval = (interval, intervalCount, t) => {
	if (interval === 'month') {
		if (intervalCount === 1) {
			return t('general.month')
		}

		return t('general.everyXMonths', { intervalCount })
	}

	if (interval === 'year') {
		if (intervalCount === 1) {
			return t('general.year')
		}
	}

	return '---'
}

export const formatBilledInterval = (interval, intervalCount, t) => {
	let intervalStr = ''

	if (interval === 'month') {
		if (intervalCount === 1) {
			intervalStr = t('general.monthly')
		} else {
			intervalStr = t('general.everyXMonths', { intervalCount })
		}
	}

	if (interval === 'year') {
		if (intervalCount === 1) {
			intervalStr = t('general.yearly')
		}
	}

	return t('general.billedXly', { interval: intervalStr })
}

export const formatPlace = (place, language, format = 'short') => {
	if (places[place]) {
		if (format === 'short') {
			return places[place][`nameShort_${translationsMapping[language]}`]
		}

		if (format === 'list') {
			return places[place][`nameList_${translationsMapping[language]}`]
		}
	}

	if (translationsMapping[language] === 'en') {
		if (place === '1') {
			return `${place}st`
		}

		if (place === '2') {
			return `${place}nd`
		}

		if (place === '3') {
			return `${place}rd`
		}

		return `${place}th`
	}

	return `${place}.`
}

export const formatSwissResult = (wins, losses, draws) => {
	if (draws && draws !== 0) {
		return `${wins}:${losses}:${draws}`
	}

	if (wins !== 0 || losses !== 0) {
		return `${wins}:${losses}`
	}

	return null
}

export const getSelection = () => {
	let text = ''

	if (window.getSelection) {
		text = window.getSelection().toString()
	} else if (document.selection && document.selection.type !== 'Control') {
		text = document.selection.createRange().text
	}

	return text
}

export const copyToClipboard = (text) => {
	const textArea = document.createElement('textarea')
	textArea.value = text

	// avoid scrolling to bottom
	textArea.style.top = '0'
	textArea.style.left = '0'
	textArea.style.position = 'fixed'

	document.body.appendChild(textArea)
	textArea.focus()
	textArea.select()

	try {
		const successful = document.execCommand('copy')

		if (successful) {

		}
	} catch (err) {
	}

	document.body.removeChild(textArea)
}

export const getYouTubeId = (url) => {
	url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/)
	return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0]
}

export const slugToCountryCode = (slug) => {
	const countryData = Object.entries(countries).find(([key, data]) => slugify(data.name_en) === slug)

	if (countryData && countryData.length === 2) {
		return countryData[0]
	}

	return null
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const formatFileSize = (bytes, si = true, dp = 1) => {
	const threshhold = si ? 1000 : 1024

	if (Math.abs(bytes) < threshhold) {
		return `${bytes} Bytes`
	}

	const units = si
		? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
		: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
	let u = -1;
	const r = 10 ** dp

	do {
		bytes /= threshhold
		u += 1
	} while (Math.round(Math.abs(bytes) * r) / r >= threshhold && u < units.length - 1)


	return `${bytes.toFixed(dp)} ${units[u]}`
}
