import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SVG from 'react-inlinesvg'

import {
	desktop, isPrerender,
	mobile,
	VIEWPORT_DESKTOP,
	VIEWPORT_MOBILE
} from './styles/media'

import LanguageMismatchHint from './layout/Banner/LanguageMismatchBanner'
import SiteHeader from './layout/Menu/SiteHeader'
import Footer from './layout/Footer'
import SiteLoading from './layout/Loading/SiteLoading'

import GlobalStyle from './styles/main'
import MobileAppMenu from './layout/Menu/MobileAppMenu'
import HTMLHead from './layout/Head/HTMLHead'
import Content from './components/layout/Content'
import { setAffiliateReturned, updateUser, updateUserSetting } from './state/actionCreators/app'
import MobileSiteHeader from './layout/Menu/MobileSiteHeader'
import MainMenu from './layout/Menu/MainMenu'
import Confirm from './components/modal/Confirm'
import Toasts from './layout/Messages/Toasts'
import CardInfoTooltip from './layout/CardInfo/CardInfoTooltip'
import Routes from './layout/Routes'
import { siteHasMultipleGames } from '../config'
import Route from './components/router/Route'
import { Front } from './pages/imports'
import PageErrorFallback from './layout/ErrorBoundary/PageErrorFallback'
import FixedCollapsibleBackdrop from './components/router/FixedCollapsibleBackdrop'
import MobileMainMenu from './layout/Menu/MobileMainMenu'
import ErrorBoundary from './layout/ErrorBoundary/ErrorBoundary'
import { getAppMenuHeight } from './helper/device'
import AccountFrozen from './layout/Loading/AccountFrozen'
import ModalForm from './components/modal/ModalForm'
import { get } from './helper/api'
import { setCollections, setCollectionsLoading } from './state/actions/persist'
import SubscriptionUnpaidWarning from './layout/Menu/SubscriptionUnpaidWarning'
import { Main as ListWithSidebarMain } from './components/layout/ListWithSidebar'
import translate from './helper/translate'
import { updateStats } from './state/actionCreators/collection'
import { haveReloaded } from './state/actions/app'
import SurveyBanner from './components/general/SurveyBanner'

class App extends Component {
	static propTypes = {
		affiliateReturnHash: PropTypes.string,
		authToken: PropTypes.string,
		cardSearchCollapsed: PropTypes.bool,
		frozenUntil: PropTypes.any,
		haveReloaded: PropTypes.func.isRequired,
		languageMismatchHint: PropTypes.bool.isRequired,
		location: PropTypes.object.isRequired,
		menuCollapsed: PropTypes.bool,
		reloadMyCollections: PropTypes.bool.isRequired,
		settings: PropTypes.object.isRequired,
		setAffiliateReturned: PropTypes.func.isRequired,
		setCollections: PropTypes.func.isRequired,
		setCollectionsLoading: PropTypes.func.isRequired,
		siteError: PropTypes.string,
		subscribed: PropTypes.string,
		subscriptionPastDue: PropTypes.bool,
		t: PropTypes.func.isRequired,
		updateStats: PropTypes.func.isRequired,
		updateUser: PropTypes.func.isRequired,
		viewport: PropTypes.string.isRequired
	}

	static defaultProps = {
		affiliateReturnHash: null,
		authToken: null,
		cardSearchCollapsed: false,
		frozenUntil: null,
		menuCollapsed: false,
		siteError: null,
		subscribed: null,
		subscriptionPastDue: false
	}

	componentDidMount() {
		const { subscribed, updateUser } = this.props

		updateUser()

		if (subscribed) {
			this.loadCollections()
		}
	}

	componentDidUpdate(prevProps) {
		const { affiliateReturnHash, authToken, haveReloaded, reloadMyCollections, setCollections, subscribed } = this.props

		if (prevProps.authToken !== authToken || prevProps.subscribed !== subscribed) {
			if (authToken && subscribed) {
				this.loadCollections()
			} else {
				setCollections(null)
			}
		}

		// reload required
		if (subscribed && prevProps.reloadMyCollections !== reloadMyCollections && reloadMyCollections === true) {
			haveReloaded('collections')
			this.loadCollections()
		}

		if (prevProps.affiliateReturnHash !== affiliateReturnHash) {
			if (affiliateReturnHash) {
				this.addAffiliateReturnListener()
			} else {
				this.removeAffiliateReturnListener()
			}
		}
	}

	loadCollections = () => {
		const { setCollections, setCollectionsLoading, t, updateStats } = this.props

		setCollectionsLoading(true)

		get('collections', ({ data: collections }) => {
			setCollections(collections.map(collection => ({
				...collection,
				number: collection.isDefault ? t('general.default') : null
			})))
			setCollectionsLoading(false)

			const collectionUpdateQueueSlugs = collections.filter(item => item.shouldUpdateStats).map(item => item.slug)

			if (collectionUpdateQueueSlugs.length > 0) {
				updateStats(collectionUpdateQueueSlugs.toString())
			}
		}, () => {

		})
	}

	addAffiliateReturnListener = () => {
		window.addEventListener('focus', this.handleAffiliateReturned)
	}

	removeAffiliateReturnListener = () => {
		window.removeEventListener('focus', this.handleAffiliateReturned)
	}

	handleAffiliateReturned = () => {
		const { setAffiliateReturned } = this.props

		setAffiliateReturned()
	}

	render() {
		const { cardSearchCollapsed, frozenUntil, languageMismatchHint, menuCollapsed, settings, siteError, subscribed, subscriptionPastDue, viewport } = this.props

		const background = this.props.location.state && this.props.location.state.background
		const location = background || this.props.location

		const isEditingDeck = location.pathname.startsWith('/deck-builder/')
		const isAddPage = location.pathname.startsWith('/add') || location.pathname.includes('/add/')
		const isCardSearch = location.pathname.startsWith('/cards') && !location.pathname.startsWith('/cards/spoiler') && !location.pathname.startsWith('/cards/ocgonly')
		const collectionUseFullWidth = (location.pathname.includes('/collection/') || location.pathname.includes('/add/')) && settings['collection.useFullWidth']
		const viewsDeck = location.pathname.startsWith('/deck/')
		const isNoneFullHeightPage = !(isEditingDeck || isCardSearch)
		const isNoneFullHeightDesktopPage = viewport === VIEWPORT_DESKTOP && isNoneFullHeightPage
		const wallpaper = (isEditingDeck || isCardSearch) && settings['app.deckBuilderWallpaper'] ? settings['app.deckBuilderWallpaper'] : null

		if (frozenUntil) {
			return <AccountFrozen until={frozenUntil} />
		}

		return (
			<>
				<HTMLHead />
				<GlobalStyle viewport={viewport} />
				<SVG src={process.env.NODE_ENV !== 'development' && !isPrerender() ? 'https://cdn.cardcluster.com/icons.6.svg' : require('../static/shared/icons.6.svg')} />

				<CardInfoTooltip />
				<Confirm />
				<ModalForm />
				{viewport === VIEWPORT_DESKTOP && <MainMenu />}
				<SiteLoading />

				<Toasts />

				{/*{viewport === VIEWPORT_DESKTOP && <SurveyBanner />}*/}

				{viewport === VIEWPORT_MOBILE && (
					<>
						<TranslucentStatusBar />
						<MobileSiteHeader />
						<MobileMainMenu />
						<MobileAppMenu />
					</>
				)}

				<Main
					id="app-main"
					wallpaper={subscribed ? wallpaper : null}
					isEditingDeck={isEditingDeck}
					viewsDeck={viewsDeck}
					menuCollapsed={isEditingDeck || menuCollapsed}
					cardSearchCollapsed={cardSearchCollapsed}
					collectionUseFullWidth={collectionUseFullWidth}
					isAddPage={isAddPage}
					isCardSearch={isCardSearch}
				>
					<MinHeight isEditingDeck={isEditingDeck}>
						{isNoneFullHeightDesktopPage && <SiteHeader />}
						{languageMismatchHint && <LanguageMismatchHint />}

						{subscriptionPastDue && <SubscriptionUnpaidWarning />}

						{siteError ? (
							<PageErrorFallback />
						) : (
							<ErrorBoundary fallback={<PageErrorFallback />}>
								{siteHasMultipleGames && <Route path="/" exact component={Front} />}

								{siteHasMultipleGames ? (
									<Route path="/:game">
										<Routes />
									</Route>
								) : (
									<Routes />
								)}
							</ErrorBoundary>
						)}

						<FixedCollapsibleBackdrop />
					</MinHeight>

					{isNoneFullHeightDesktopPage && <Footer />}
				</Main>
			</>
		)
	}
}

const Main = styled.main`
	background-color: ${props => props.theme.background};

	${props => props.wallpaper && `
		background-image: url(${props.wallpaper});
		background-size: cover;
		background-position: center;
	`}

	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;

	.pull-to-refresh-material2__icon {
		color: #4285f4;
	}

	${mobile`
		height: calc(100vh - 44px);
		height: calc(var(--vh, 1vh) * 100 - 44px);
		padding-bottom: ${getAppMenuHeight()};

		position: fixed;
		top: 44px;
		left: 0;
		width: 100%;

		${props => (props.isEditingDeck || props.viewsDeck) && `
			height: calc(100vh - 44px);
			height: calc(var(--vh, 1vh) * 100 - 44px);
			top: 44px;
		`}

		${props => props.isAddPage && `
			padding-top: calc(41px + 1rem);
		`}

		${props => props.isCardSearch && `
			top: 0;
		`}

		${Content} {
			padding: 1rem;
		}
	`}

	${desktop`
		padding-left: 300px;
		flex: 1;

		${Content} {
			padding: 0 2rem 2rem;

			${props => props.collectionUseFullWidth && `
				&, ${ListWithSidebarMain} {
					max-width: none;
				}
			`}
		}

		${props => (props.menuCollapsed || props.collectionUseFullWidth) && `
			padding-left: 80px;
		`}

		${props => props.isEditingDeck && !props.cardSearchCollapsed && `
			padding-left: 420px;
		`}
	`}
`

const MinHeight = styled.div`
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);

	${desktop`
		${props => props.isEditingDeck && `
			min-height: calc(100vh - 80px);
			min-height: calc(var(--vh, 1vh) * 100 - 80px);
		`}
	`}

	${mobile`
		min-height: calc(100vh - 50px - 44px);
		min-height: calc(var(--vh, 1vh) * 100 - 50px - 44px);
	`}
`

const TranslucentStatusBar = styled.div`
	position: fixed;
	top: -30px;
	left: 0;
	z-index: ${props => props.theme.zLayer7};

	background: #2D2D2D;
	height: 30px;
	width: 100%;
`

const enhance = compose(
	connect(state => ({
		affiliateReturnHash: state.app.affiliateReturnHash,
		authToken: state.persist.authToken,
		cardSearchCollapsed: state.persist.cardSearchCollapsed,
		deck: state.deckbuilder.deck,
		frozenUntil: state.persist.user.frozenUntil,
		languageMismatchHint: state.persist.languageMismatchHint,
		menuCollapsed: state.persist.menuCollapsed,
		reloadMyCollections: state.app.reloadMyCollections,
		settings: state.persist.user.settings,
		siteError: state.app.siteError,
		subscribed: state.persist.user.subscribed,
		subscriptionPastDue: state.persist.user.subscriptionPastDue,
		viewport: state.app.viewport
	}), dispatch => bindActionCreators({
		haveReloaded,
		setAffiliateReturned,
		setCollections,
		setCollectionsLoading,
		updateStats,
		updateUser,
		updateUserSetting
	}, dispatch)),
	withRouter,
	translate('general')
)

export default enhance(App)
