import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import styled, { withTheme } from 'styled-components'
import { withRouter } from 'react-router-dom'
import { desktop, mobile, VIEWPORT_DESKTOP, VIEWPORT_MOBILE } from '../styles/media'
import translate from '../helper/translate'
import Icon from '../components/general/Icon'
import { translationsMapping } from '../../config'
import LogoImage from '../images/logo/cardcluster-white.svg'
import Link from '../components/router/Link'
import { changeCardNameLanguage, changeLanguage } from '../state/actionCreators/app'
import references from '../data/references'
import { setReference } from '../state/actions/app'
import { footerMenuCommunity, footerMenuHelp, footerMenuMisc, footerMenuProducts } from '../data/mainMenu'
import BetterButton, { StyledBetterButton } from '../components/button/BetterButton'

const Footer = ({ language, location, onBeforeNavigate, setReference, siteError, t, theme, viewport }) => {
	const showEnglish = translationsMapping[language] === 'en'

	const social = (
		<Social>
			<List>
				<a href={`https://twitter.com/cardcluster${translationsMapping[language] === 'de' ? 'DE' : ''}`}>
					<Icon name="social-twitter" />
				</a>
				<a href="https://www.instagram.com/cardcluster">
					<Icon name="social-instagram" />
				</a>
			</List>
		</Social>
	)

	const feedback = (
		<Feedback>
			{showEnglish && <p>Questions, found a bug<br />or have a great idea what we could improve?</p>}
			{!showEnglish && <p>Fragen, einen Fehler gefunden<br />oder eine tolle Idee, was wir verbessern könnten?</p>}
			<BetterButton to="/support" onBeforeNavigate={onBeforeNavigate} color={theme.secondary} small={viewport === VIEWPORT_MOBILE}>{t('general.giveFeedback')}</BetterButton>
		</Feedback>
	)

	const menu = (
		<MenuColumns>
			<Menu>
				<Heading>{t('general.products')}</Heading>
				{footerMenuProducts.map(item => (
					<Link
						to={item.to}
						onBeforeNavigate={() => {
							setReference(references.FOOTER)
							if (onBeforeNavigate) {
								onBeforeNavigate()
							}
						}}
						key={item.to}
					>
						{t(item.name)}
					</Link>
				))}

				{viewport === VIEWPORT_DESKTOP && (
					<MenuLinkPlaceholder />
				)}
				<br />

				<Heading>{t('general.support')}</Heading>
				{footerMenuHelp.map(item => (
					<Link to={item.to} onBeforeNavigate={onBeforeNavigate} key={item.to}>
						{t(item.name)}
					</Link>
				))}
			</Menu>
			<Menu>
				<Heading>{t('general.community')}</Heading>
				{footerMenuCommunity.map(item => (
					<Link to={item.to} onBeforeNavigate={onBeforeNavigate} external={item.external} key={item.to}>
						{t(item.name)}
					</Link>
				))}

				<br />

				<Heading>{t('general.company')}</Heading>
				{footerMenuMisc.map(item => (
					<Link to={item.to} onBeforeNavigate={onBeforeNavigate} key={item.to}>
						{t(item.name)}
					</Link>
				))}
			</Menu>
		</MenuColumns>
	)

	return (
		<StyledFooter>
			<Top>
				<Left>
					{!showEnglish ? (
						<Text>
							<p>
								<Logo><img src={LogoImage} alt="cardcluster Logo" /></Logo>
							</p>
							<p>
								<strong>cardcluster</strong> ist die neue All-in-One Yu-Gi-Oh! Datenbank, Deck Builder
								und Collection Manager App. Unser Ziel ist es, Spieler und Sammler mit den bestmöglichen
								digitalen Tools und Ressourcen auszustatten.
							</p>

							<p>
								Jeder kann auf cardcluster Decks erstellen.
								Einfach <Link to="/signup">kostenlos registrieren</Link> und ausprobieren!
							</p>

							{social}

							{feedback}

							{viewport === VIEWPORT_MOBILE && menu}

							<Disclaimer>
								<p><b>Link Disclaimer</b></p>

								<p>
									Für manche Links kann cardcluster eine Affiliate-Provision erhalten, wenn du diese
									besuchst
									oder Produkte darüber erwirbst. Dies hilft uns, kostenlose Produkte und Inhalte auf
									cardcluster anbieten zu können.
								</p>
							</Disclaimer>
						</Text>
					) : (
						<Text>
							<p>
								<Logo><img src={LogoImage} alt="cardcluster Logo" /></Logo>
							</p>
							<p>
								<strong>cardcluster</strong> is the new all-in-one Yu-Gi-Oh! Database, Deck Builder
								and Collection Manager app. Our goal is to provide players and collectors with the best
								digital tools and resources possible.
							</p>
							<p>
								Anyone can create decks on cardcluster. Just <Link to="/signup">sign up for free</Link> and try it!
							</p>

							{social}

							{feedback}

							{viewport === VIEWPORT_MOBILE && menu}

							<Disclaimer>
								<p><b>Link Disclaimer</b></p>

								<p>
									For some links, cardcluster may receive an affiliate commission if you visit them
									or purchase products through them. This helps us to offer free products and content
									on
									cardcluster.
								</p>
							</Disclaimer>
						</Text>
					)}
				</Left>
				{viewport === VIEWPORT_DESKTOP && (
					<Right>
						{menu}
					</Right>
				)}
			</Top>

			<Copyright>
				&copy; {new Date().getFullYear()} cardcluster
			</Copyright>
		</StyledFooter>
	)
}

Footer.propTypes = {
	language: PropTypes.string.isRequired,
	location: PropTypes.object.isRequired,
	onBeforeNavigate: PropTypes.func,
	setReference: PropTypes.func.isRequired,
	siteError: PropTypes.string,
	t: PropTypes.func.isRequired,
	theme: PropTypes.object.isRequired,
	viewport: PropTypes.string.isRequired
}

Footer.defaultProps = {
	onBeforeNavigate: undefined,
	siteError: null
}

const Feedback = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	gap: 4rem;

	${StyledBetterButton} {
		width: fit-content;
	}

	p {
		color: rgba(255, 255, 255, 0.7);
		font-size: 1.1rem;
		font-weight: 500;
		margin: 0;
	}

	${desktop`
		border: 2px solid #444;
		border-radius: 20px;
		padding: 1rem 1.5rem;
	`}

	${mobile`
		background: rgba(255, 255, 255, 0.07);
        padding: 1.5rem 2rem;
        margin-left: -30px;
        width: calc(100% + 60px);

		align-items: flex-start;
		flex-direction: column;
		gap: 1.5rem;
		margin-bottom: 2rem;
		padding: 1.5rem 2rem;

		p, ${StyledBetterButton} {
			font-size: 0.9rem;
		}
	`}
`

const StyledFooter = styled.footer`
	background: ${props => props.theme.header};
	display: flex;
	flex-direction: column;
	padding: 4rem;

	${desktop`
		align-items: center;
		justify-content: center;
		margin-top: 5rem;
	`}

	${mobile`
		flex-direction: column;
		padding: 2rem 30px;
		margin-top: 2rem;
	`}

	> p {
		font-size: 1.2rem;
	}

	.react-select__control {
		background: #333 !important;
		color: #FFF !important;
		margin-top: 0.5rem;

		&:not(.react-select__control--menu-is-open) {
			border-color: #666 !important;
		}
	}
`

const Top = styled.div`
	display: flex;

	${mobile`
		flex-direction: column;
	`}
`

const Left = styled.div`

`

const CardclusterPerk = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 1rem;

	background: rgba(255, 255, 255, 0.05);
	border-radius: 24px;
	padding: 1.5rem 2rem;

	p {
		color: rgba(255, 255, 255, 0.7);
		font-size: 0.9rem;
		margin-bottom: 0;
	}
`

const CardclusterPerkHeading = styled.div`
	font-weight: 500;
	margin-bottom: 0.5rem;

	align-items: center;
	display: flex;

	svg {
		color: rgba(255, 255, 255, 0.7);
		margin-right: 1rem;
	}
`

const Disclaimer = styled.small`
	display: block;
	line-height: 1;
	margin-top: 2rem;

	p {
		font-size: 0.8rem;
		margin-bottom: 0.5rem;
	}
`

const Heading = styled.div`
	color: #FFF;
	font-size: 1.1rem;
	font-weight: bold;
	margin-bottom: 1.5rem;
`

const Text = styled.div`
	color: #EEE;
	max-width: 700px;

	> :last-child {
		margin-bottom: 0;
	}

	p > a {
		color: #FFF;
		font-weight: bold;
		text-decoration-color: ${props => props.theme.primary};
	}
`

const Logo = styled.span`
	img {
		height: 30px;
	}
`

const MenuColumns = styled.div`
	display: flex;
	gap: 4rem;

	${mobile`
		flex-direction: column;
		gap: 2rem;
	`}
`

const Menu = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 300px;
	flex: 1;

	> a, > div > a {
		color: #AAA;
		display: block;
		cursor: pointer;
		font-size: 1rem;
		margin-bottom: 1rem;
		text-decoration: none;
		width: fit-content;
		white-space: nowrap;

		${desktop`
			&:hover {
				color: #FFF;
			}
		`}

		&:last-child {
			margin-bottom: 0;
		}
	}
`

const Right = styled.div`
	${mobile`
		margin-top: 3rem;
	`}

	${desktop`
		margin-left: 8rem;
	`}
`

const Social = styled.div`
	margin-top: 2rem;

	p {
		font-size: 1rem;
	}
`

const List = styled.div`
	align-items: center;
	display: flex;
	gap: 1rem;
	margin-bottom: 2rem;

	a {
		color: #AAA;

		${desktop`
			&:hover {
				color: #FFF;
			}
		`}
	}

	&:last-child {
		margin-right: 0;
	}
`

const Copyright = styled.div`
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.9rem;
	margin-top: 3.5rem;
	text-align: center;
`

const MenuLinkPlaceholder = styled.div`
	height: calc(18px + 1rem);
`

const mapStateToProps = state => ({
	language: state.persist.language,
	siteError: state.app.siteError,
	viewport: state.app.viewport
})

const mapDispatchToProps = dispatch => bindActionCreators({
	changeCardNameLanguage,
	changeLanguage,
	setReference
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	translate('general'),
	withRouter,
	withTheme
)

export default enhance(Footer)
