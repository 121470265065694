import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { desktop } from '../../styles/media'
import Link from '../../components/router/Link'
import Icon from '../../components/general/Icon'
import translate from '../../helper/translate'
import { events, trackEvent } from '../../helper/track'
import StyledMenu from '../../components/nav/StyledMenu'
import { mainMenuEntries } from '../../data'
import { siteHasMultipleGames, translationsMapping } from '../../../config'
import ProTag from '../../components/general/ProTag'
import Tag from '../../components/general/Tag'
import Tooltip from '../../components/general/Tooltip'
import { scrollToPosition } from '../../helper/scroll'
import { StyledInputContainer } from '../../components/form/Input'
import { getPageLocation } from '../../helper/router'
import { StyledDeckName } from '../../components/deck/DeckName'
import DiscordWidget from '../DiscordWidget'
import references from '../../data/references'
import { setReference } from '../../state/actions/app'
import SiteSettings from './SiteSettings'
import BetterButton from '../../components/button/BetterButton'
import { Login, Register } from '../../pages/imports'
import themeLight from '../../styles/theme-light'
import { StyledUnread } from '../../components/general/Unread'
import { StyledCoverImage } from '../../pages/deck/components/CoverImage'
import { footerMenuHelp, footerMenuMisc, footerMenuProducts } from '../../data/mainMenu'
import Footer from '../Footer'

class MobileMainMenu extends Component {
	static propTypes = {
		authToken: PropTypes.string,
		deck: PropTypes.object,
		history: PropTypes.any.isRequired,
		language: PropTypes.string.isRequired,
		location: PropTypes.object.isRequired,
		menuCollapsed: PropTypes.bool,
		setReference: PropTypes.func.isRequired,
		subscribed: PropTypes.string,
		t: PropTypes.func.isRequired,
		userId: PropTypes.number.isRequired
	}

	static defaultProps = {
		authToken: null,
		deck: null,
		menuCollapsed: false,
		subscribed: null
	}

	constructor(props) {
		super(props)

		this.state = {
			menuTransitions: true,
			visible: false
		}

		this.unlisten = this.props.history.listen(this.closeMenu)

		this.mainMenuRef = React.createRef()
		this.backdropRef = React.createRef()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.visible !== this.state.visible) {
			if (this.mainMenuRef.current) {
				if (this.state.visible) {
					this.mainMenuRef.current.style.transform = 'translate(0)'
				} else {
					this.mainMenuRef.current.style.transform = 'translate(-100%)'
				}
			}
		}
	}

	componentWillUnmount() {
		this.unlisten()
		clearTimeout(this.menuTransitionsTimeout)
	}

	handleOnBeforeNavigate = (close = false) => {
		this.setState({
			menuTransitions: false
		}, () => {
			if (close) {
				this.closeMenu()
			}
		})
	}

	handleMenuToggle = () => {
		this.setState(prevState => ({
			...prevState,
			visible: !prevState.visible
		}), () => {
			this.mainMenuRef.current.scrollTo(0, 0)

			if (this.state.visible) {
				trackEvent(events.CLOSE_MENU)
			} else {
				trackEvent(events.OPEN_MENU)
			}
		})
	}

	closeMenu = () => {
		this.setState({ visible: false })
		this.menuTransitionsTimeout = window.setTimeout(() => this.setState({ menuTransitions: true }), 500)

		if (this.mainMenuRef.current) {
			scrollToPosition(0, this.mainMenuRef.current)
		}
	}

	render() {
		const { authToken, deck, language, location, setReference, subscribed, userId, t } = this.props
		const { menuTransitions, visible } = this.state

		const pageLocation = getPageLocation(location)
		const isEditingDeck = !!(pageLocation.pathname.startsWith('/deck-builder/') && deck && deck.user.id === userId)
		const isCollection = pageLocation.pathname.startsWith('/collection/')

		const menuCollapsed = this.props.menuCollapsed || isEditingDeck || isCollection

		if (location.pathname.startsWith('/login') || location.pathname.startsWith('/signup') || (siteHasMultipleGames && location.pathname === '/')) {
			return null
		}

		return ReactDOM.createPortal((
			<>
				{visible && (
					<Helmet>
						<html className="offscreen" />
					</Helmet>
				)}
				<MenuCheckbox
					type="checkbox"
					id="toggle-main-menu"
					checked={visible}
					onChange={this.handleMenuToggle}
				/>

				<MainNav
					menuCollapsed={menuCollapsed}
					menuTransitions={menuTransitions}
					ref={this.mainMenuRef}
				>
					<NavHeader>
						<SiteSettings onBeforeNavigate={() => this.handleOnBeforeNavigate(true)} />
					</NavHeader>

					{!authToken && (
						<SignUpButtons>
							<BetterButton
								small
								to="/login"
								onMouseOver={() => Login.preload()}
								color={themeLight.secondary}
							>
								{t('general.login')}
							</BetterButton>
							<BetterButton
								small
								to="/signup"
								onMouseOver={() => Register.preload()}
							>
								{t('general.register')}
							</BetterButton>
						</SignUpButtons>
					)}

					<StyledMenu>
						{!subscribed && (
							<>
								<MobileMenuLink
									to="/product/collection-manager"
									onBeforeNavigate={() => {
										this.handleOnBeforeNavigate()
										setReference(references.USER_MENU)
									}}
								>
									<Icon name="archive" />
									<span>{t('general.collectionManager')}</span>
								</MobileMenuLink>
								<MobileMenuLink
									to="/pricing"
									onBeforeNavigate={() => {
										this.handleOnBeforeNavigate()
										setReference(references.USER_MENU)
									}}
								>
									<Icon name="star" />
									<span>cardcluster<ProTag /></span>
								</MobileMenuLink>
								<Separator />
							</>
						)}

						<SeparatorHeading>{t('general.browse')}</SeparatorHeading>

						<MobileMenuLink to="/" active={location.pathname === '/'} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="home" />
							<span>{t('general.home')}</span>
						</MobileMenuLink>
						<MobileMenuLink to="/cards" active={location.pathname.startsWith('/cards') && !location.pathname.startsWith('/cards/spoiler') && !location.pathname.startsWith('/cards/ocgonly')} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="search" />
							<span>{t('general.cardSearch')}</span>
						</MobileMenuLink>

						<Separator />

						<MobileMenuLink to="/decks" active={location.pathname === '/decks'} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="fire" />
							<span>{t('general.topDecks')}</span>
						</MobileMenuLink>

						<MobileMenuLink to="/sets" active={location.pathname === '/sets'} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="folder" />
							<span>{t('general.sets')}</span>
						</MobileMenuLink>
						<MobileMenuLink to="/themes" active={location.pathname === '/themes'} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="tag" />
							<span>{t('general.themes')}</span>
						</MobileMenuLink>
						<MobileMenuLink to="/tournaments" active={location.pathname === '/tournaments'} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="trophy" />
							<span>{t('general.events')}</span>
						</MobileMenuLink>
						<MobileMenuLink to="/community" active={location.pathname === '/community'} onBeforeNavigate={this.handleOnBeforeNavigate}>
							<Icon name="chat-alt-2" />
							<span>{t('general.community')}</span>
						</MobileMenuLink>

						<Separator />

						<SeparatorHeading>{t('general.database')}</SeparatorHeading>

						{mainMenuEntries(t).map(entry => (
							<MobileMenuLink to={entry.to} active={location.pathname === entry.to} onBeforeNavigate={this.handleOnBeforeNavigate} key={entry.to}>
								{entry.name}
							</MobileMenuLink>
						))}

						<Footer onBeforeNavigate={this.handleOnBeforeNavigate} />
					</StyledMenu>
				</MainNav>

				<Backdrop
					htmlFor="toggle-main-menu"
					menuTransitions={menuTransitions}
					ref={this.backdropRef}
				/>
				<MenuBackdrop menuVisible={visible} menuTransitions={menuTransitions} />
			</>
		), document.getElementById('root-modal'))
	}
}

const MenuBackdrop = styled.div`
	background: ${props => props.theme.background};
	position: fixed;
	top: 0;
	right: 0;
	height: 100%;
	width: 85%;
	transition: ${props => props.menuVisible ? 'transform 0.2s' : 'transform 0.3s, z-index 0s 0.2s'};
	${props => !props.menuTransitions && 'transition: none;'}
	z-index: ${props => props.menuVisible ? props.theme.zLayer7 : -1};

	${desktop`
		width: 340px;
	`}

	display: none;
`

const MenuCheckbox = styled.input`
	position: absolute;
	left: -50px;
	visibility: hidden;
`

const Backdrop = styled.label`
	background: ${props => props.theme.id === 'dark' ? 'rgba(0, 0, 0, 0.85)' : 'rgba(0, 0, 0, 0.2)'};
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	opacity: 0;
	transition: ${props => props.menuTransitions ? 'opacity 0.3s' : 'none'};
	width: 100%;

	position: fixed;
	top: 0;
	left: 0;
	transform: translate(-100%);
	z-index: ${props => props.menuTransitions ? props.theme.zLayer8 : -1};

	${MenuCheckbox}:checked ~ & {
		opacity: 1;
		transform: translate(0);
	}
`

const RelativeWrapper = styled.div`
	height: 50px;
	position: relative;

	img {
    	height: 30px;
    	width: 30px;
	}

	a {
		height: 50px;
		padding-top: 0;
		padding-bottom: 0;

		> div:first-child {
			position: relative;
			left: -0.3rem;
		}

		span:last-child {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 180px;
		}
	}

	${StyledDeckName} {
		max-width: none;
   		overflow: visible
	}
`

const MobileMenuLink = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;

	background: transparent;
	border: 0;
	color: ${props => props.theme.backgroundSolid};
	font-weight: 400;
	font-size: 1.2rem;
	padding: 0 0.7rem;
	text-decoration: none;
	white-space: nowrap;

	${props => props.main && `
		font-size: 1rem;
		height: 80px;
		padding: 0 0.5rem;
		transition: color 0.1s ease-out;
		white-space: nowrap;

		&:hover {
			background: rgba(0, 0, 0, 0.2);
			color: #FFF;
			cursor: pointer;
			opacity: 1;
		}
	`}

	${props => props.bold && `
		color: #FFF;
		font-weight: 600;
		opacity: 1;

		&::after {
			content: '→';
			display: inline-block;
			margin-left: 0.25rem;
		}
	`}

	${props => props.red && `
		background: rgba(204, 30, 34, 0.5);
		border-radius: 4px;
		margin-right: 4px;
		padding: 0 0.6rem;
		height: 36px !important;
		width: 36px !important;
		transition: 0.2s;

		&:active {
			background: rgba(204, 30, 34, 1);
		}
	`}

	&:first-child {
		margin-left: 0;
	}

	& > span:not(${StyledUnread}) {
		margin-left: 1.3rem;
	}

	${StyledUnread} {
		margin-left: auto;
	}

	${StyledCoverImage} {
		margin-right: 0.5rem;
	}

	em {
		color: ${props => props.theme.orange};
		font-size: 1rem;
		font-weight: 600;
		margin-left: 0.35rem;
	}
`

const MainNav = styled.nav`
	background: ${props => props.theme.id === 'dark' ? props.theme.backgroundLightest : props.theme.background};
	color: ${props => props.theme.text};
	position: fixed;
	top: 0;
	left: 0;

	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
	max-width: 300px;

	transition: ${props => props.menuTransitions ? 'transform 0.3s' : 'none'};
	transform: translate(-100%);
	z-index: ${props => props.theme.zLayer10};

	display: flex;
	flex-direction: column;
	padding: 0.5rem 0 0 0;

	${MenuCheckbox}:checked ~ & {
		transform: translate(0);
	}

	${StyledMenu} {
		${MobileMenuLink} {
			justify-content: flex-start;
			padding: 0.9rem 30px;
			width: 100%;;
		}
	}

	${StyledMenu} {
		flex: 0 1 auto;

		> a, ${RelativeWrapper} > a {
			border-bottom: 1px solid ${props => props.theme.backgroundLight};
			color: ${props => props.theme.text};
			transition: background 0.1s;

			&:active {
				background: ${props => props.theme.backgroundLight};
			}

			&:last-child {
				border-bottom: 0;
			}

			${Tag} {
				margin-left: 0.5rem !important;
			}

			${desktop`
				height: 50px;
				min-height: 50px;

				&:hover {
					background: ${props => props.theme.backgroundVeryLight};

					> svg {
						color: #FFF;
					}
				}
			`}
		}

		> a > svg, ${RelativeWrapper} > a > svg {
			color: ${props => props.theme.textLight};
		}
	}

	${desktop`
		padding: 0.5rem 0 0;
		width: 300px;

		${props => props.menuCollapsed && `
			overflow: hidden;
			width: 80px;
		`}
	`}

	${props => props.dark && `
		background: ${props.theme.header};
		color: #FFF;

		${StyledMenu} > a, ${StyledMenu} ${RelativeWrapper} > a {
			border-radius: 12px;
			border-bottom: 0;
			color: #FFF;
			margin: 0 1rem 1px;
			padding: 0.9rem 1rem !important;
			position: relative;
			width: calc(100% - 2rem) !important;

			> svg {
				color: rgba(255, 255, 255, 0.6);
			}

			&:hover {
				background: rgba(255, 255, 255, 0.1);

				> svg {
					color: ${props => props.theme.primary};
				}
			}
		}
	`}

	&.notransition {
		transition: none !important;
	}
`

const NavHeader = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	min-height: 88px;
	padding: 0 1rem 0 30px;
`

const SignUpButtons = styled.div`
	align-items: center;
	display: flex;

	padding: 0 1rem 1rem 30px;

	> * + * {
		margin-left: 1rem;
	}
`

const Separator = styled.div`
	height: 23px;
	min-height: 23px;
`

const FooterLink = styled(Link)`
	border-bottom: 0 !important;
	color: ${props => props.theme.textLight} !important;
	display: block;
	font-size: 0.8rem;
	font-weight: 600;
	padding: 0 0.5rem 0.5rem 0;
	text-decoration: none;

	${desktop`
		&:hover {
			background: transparent !important;
			color: ${props => props.theme.primary} !important;
		}
	`}
`

const FooterLinks = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 0 30px;

	${StyledInputContainer} {
		margin-bottom: 0.5rem;
		width: 100%;
	}

	${props => props.social && `
		${FooterLink} {
			padding: 0 0.75rem 0.75rem 0;

			svg:last-child {
				display: none;
			}
		}
	`}
`

const SeparatorHeading = styled.div`
	color: ${props => props.theme.textVeryLight};
	font-size: 0.9rem;
	font-weight: 600;
	margin: 0 0 0.25rem 30px;
	text-transform: uppercase;
`

const mapStateToProps = state => ({
	authToken: state.persist.authToken,
	deck: state.deckbuilder.deck,
	language: state.persist.language,
	menuCollapsed: state.persist.menuCollapsed,
	subscribed: state.persist.user.subscribed,
	userId: state.persist.user.id
})

const mapDispatchToProps = dispatch => bindActionCreators({
	setReference
}, dispatch)

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
	translate('general')
)

export default enhance(MobileMainMenu)
