import TCGplayerLogoRow from '../images/partner/tcgplayer-column-rgb.png'
import CardmarketLogoRow from '../images/partner/cardmarket-row-blue.png'
import CardmarketLogoRowWhite from '../images/partner/cardmarket-row-white.png'
import CardmarketLogoColumn from '../images/partner/cardmarket-column-blue.png'
import CardmarketLogoColumnWhite from '../images/partner/cardmarket-column-white.png'
import { cardNameLanguageMapping } from '../../config'

export const TCG_PLAYER = 1
export const CARD_MARKET = 2

const priceSources = {
	[TCG_PLAYER]: {
		name: 'TCGplayer',
		currency: 'USD',
		logo: TCGplayerLogoRow,
		logoWhite: TCGplayerLogoRow,
		logoColumn: TCGplayerLogoRow,
		logoColumnWhite: TCGplayerLogoRow,
		icon: 'currency-dollar',
		affiliate: true,
		color: 'tcgplayer',
		buildLink: (externalLink) => {
			if (!externalLink) return null

			const url = `https://www.tcgplayer.com${externalLink}`

			if (process.env.NODE_ENV === 'development') {
				return url
			}

			return `https://tcgplayer.pxf.io/XYky6X?u=${encodeURIComponent(url)}`
		}
	},
	[CARD_MARKET]: {
		name: 'Cardmarket',
		currency: 'EUR',
		logo: CardmarketLogoRow,
		logoWhite: CardmarketLogoRowWhite,
		logoColumn: CardmarketLogoColumn,
		logoColumnWhite: CardmarketLogoColumnWhite,
		icon: 'currency-euro',
		affiliate: true,
		color: 'cardmarket',
		buildLink: (externalLink, userLanguage, settings = null) => {
			if (!externalLink) return null

			const language = cardNameLanguageMapping[userLanguage]
			const cardmarketLanguage = ['en', 'de', 'fr', 'it', 'es'].find(item => item === language) ? language : 'en'

			const affiliateParams = process.env.NODE_ENV === 'development' ? '?' : '?utm_source=cardcluster&utm_medium=text&utm_campaign=card_prices'

			let filterParams = ''

			if (settings && externalLink !== '/YuGiOh/Wants') {
				const filterSellerType = settings['site.cardmarketSellerType']
				const filterSellerReputation = settings['site.cardmarketSellerReputation']
				const filterLanguage = settings['site.cardmarketLanguage']
				const filterMinCondition = settings['site.cardmarketMinCondition']

				if (filterSellerType && Array.isArray(filterSellerType) && filterSellerType.length > 0) {
					filterParams += `&sellerType=${filterSellerType.toString()}`
				}

				if (filterSellerReputation && Number(filterSellerReputation) !== 5) {
					filterParams += `&sellerReputation=${filterSellerReputation}`
				}

				if (filterLanguage && Array.isArray(filterLanguage) && filterLanguage.length > 0) {
					filterParams += `&language=${filterLanguage.toString()}`
				}

				if (filterMinCondition && Number(filterMinCondition) !== 7) {
					filterParams += `&minCondition=${filterMinCondition}`
				}
			}

			return `https://www.cardmarket.com/${cardmarketLanguage}${externalLink}${affiliateParams}${filterParams}`
		}
	}
}

export default priceSources
