import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledIcon } from '../general/Icon'
import Tooltip from '../general/Tooltip'
import { desktop } from '../../styles/media'

const IconSwitch = ({ children, disabled, onChange, onClick, transparent, value }) => (
	<StyledIconSwitch onClick={onClick} transparent={transparent} disabled={disabled}>
		{React.Children.map(children, child => !child.props.hidden ? (
			<Tooltip title={child.props.title}>
				<IconButton
					active={child.props.value === value}
					onClick={() => onChange(child.props.value)}
					transparent={transparent}
				>
					{child}
				</IconButton>
			</Tooltip>
		) : null)}
	</StyledIconSwitch>
)

IconSwitch.propTypes = {
	children: PropTypes.any.isRequired,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired,
	onClick: PropTypes.func,
	transparent: PropTypes.bool,
	value: PropTypes.any.isRequired
}

IconSwitch.defaultProps = {
	disabled: null,
	onClick: undefined,
	transparent: false
}

export const StyledIconSwitch = styled.div`
	border: 1px solid ${props => props.transparent ? props.theme.shadowThick : props.theme.backgroundLight};
	border-radius: 3px;

	align-items: center;
	display: flex;

	${props => props.disabled && `
		opacity: 0.5;
		pointer-events: none;
	`}
`

const IconButton = styled.button`
	background: ${props => props.active ? '#444' : 'transparent'};
	border: 0;
	border-radius: 3px;
	color: ${props => props.active ? '#EEE' : props.theme.textVeryLight};

	align-items: center;
	display: flex;
	justify-content: center;

	height: 36px;
	width: 36px;
	min-width: 36px;

	${desktop`
		&:hover {
			color: ${props => props.active ? '#EEE' : props.theme.textLight};
		}
	`}

	${StyledIcon} {
		min-width: 20px;
	}

	${props => props.transparent && `
		background: ${props.active ? '#444' : 'rgba(255, 255, 255, 0.1)'};
		color: ${props.active ? 'rgba(255, 255, 255)' : 'rgba(255, 255, 255, 0.7)'};
	`}
`

export default IconSwitch
