import { discordLink } from '../../config'

export const mainMenu = [
	{
		name: 'general.spoiler',
		to: '/cards/spoiler'
	},
	{
		name: 'general.limited',
		nameShort: 'general.limitedShort',
		to: '/limited-list/tcg'
	},
	{
		name: 'general.alternateArtworks',
		to: '/alternateartworks'
	},
	{
		name: 'general.ocgOnlyCards',
		to: '/cards/ocgonly'
	}
]

export const footerMenuProducts = [
	{
		name: 'general.collectionManager',
		to: '/product/collection-manager'
	},
	{
		name: 'general.cardclusterPro',
		to: '/pricing'
	}
]

export const footerMenuHelp = [
	{
		name: 'general.docs',
		to: '/docs',
		icon: 'book-open'
	},
	{
		name: 'general.help',
		to: '/support',
		icon: 'support'
	},
	{
		name: 'general.installApp',
		to: '/install',
		icon: 'device-mobile'
	}
]

export const footerMenuMisc = [
	{
		name: 'general.about',
		to: '/about'
	},
	{
		name: 'general.imprint',
		to: '/legal'
	},
	{
		name: 'general.privacy',
		to: '/privacy'
	},
	{
		name: 'general.terms',
		to: '/terms'
	},
]

export const footerMenuCommunity = [
	{
		name: 'general.blog',
		to: '/blog'
	},
	{
		name: 'general.roadmap',
		to: '/roadmap'
	},
	{
		name: 'general.discord',
		to: discordLink,
		external: true
	}
]
