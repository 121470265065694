import {
	RESET_USER,
	SET_CARD_SEARCH,
	SET_LANGUAGE,
	SET_USER,
	SET_USER_SETTING,
	SET_COUPON,
	TOGGLE_MENU,
	TOGGLE_CARD_SEARCH,
	SET_UNREAD_COMMENTS,
	SET_UNREAD,
	SET_REJECTED_COMMENTS,
	SET_CARD_NAME_LANGUAGE,
	SET_USER_SETTINGS,
	SET_COLLECTIONS,
	SET_COLLECTIONS_LOADING, APPLY_COLLECTION_UPDATES
} from '../actions/persist'
import { events, trackEvent } from '../../helper/track'
import { TCG } from '../../data/formats'

const initialState = {
	authToken: null,
	cardSearchCollapsed: false,
	coupon: null,
	language: null,
	languageMismatchHint: false,
	menuCollapsed: false,
	rejectedComments: null,
	unreadAdmin: null,
	unreadComments: null,
	savedSearchQueries: null,

	collections: null,
	collectionsLoading: false,

	search: {
		q: '',
		filter: {},
		load: false,
		collection: null,
		sortBy: 'lastPrint',
		sortOrder: 'default',
		view: 'tile'
	},
	user: {
		id: 0,
		username: '',
		emailIsVerified: false,
		avatar: '',
		role: 'guest',
		subscribed: null,
		settings: {
			// app
			'app.cookiesAccepted': null,

			// deck
			'app.deckView': 'list',
			'app.autoSort': false,

			'app.appearance': 'light',
			'app.cardNameLanguage': null,
			'app.priceSource': null,
			'app.priceTrendAbsolute': false,
			'app.deckSortAlgorithmMain': ['primary', 'theme', 'secondary', 'copies', 'a-z'],
			'app.deckSortAlgorithmExtra': ['secondary', 'levelDesc', 'atkDesc', 'a-z'],
			'app.deckDefaultFormat': TCG,
			'app.deckDefaultSeason': null,
			'app.deckDefaultSeasonUseCurrent': true,
			'app.deckDefaultVisibility': 'public',
			'app.decklistLanguage': null,
			'app.cardSearchDefaultMode': 'name',
			'app.deckCollapseGuide': false,
			'app.deckCollapseWatchlist': false,
			'app.deckCollapseChecklist': false,
			'app.deckCollapseSimilarDecks': false,
			'app.deckCollapsePublish': false,
			'app.deckCollapseThemes': false,
			'app.mydecksFilterArchetype': null,
			'app.mydecksFilterCards': null,
			'app.mydecksFilterEvent': null,
			'app.mydecksFilterExtraDeckSize': null,
			'app.mydecksFilterFormat': null,
			'app.mydecksFilterMainDeckSize': null,
			'app.mydecksFilterSeason': null,
			'app.mydecksFilterSortBy': 'updatedAt',
			'app.mydecksFilterTags': null,
			'app.mydecksFilterVisibility': null,
			'app.mydecksView': 'list',
			'app.myfavoritesView': 'tile',

			// collection
			'collection.view': 'list',
			'collection.sortBy': 'name',
			'collection.addMode': 'cardNumber',
			'collection.whatToCapture': 'haves',
			'collection.columns': ['haves', 'wants', 'cardNumber', 'name', 'price', 'rarity', 'language', 'edition', 'condition'],
			'collection.useFullWidth': false,
			'collection.cardsPerPage': 50,
			'collection.showOwnership': true,
			'collection.grayOutMissingCards': true,
			'collection.showPresets': true,
			'collection.filterNameStartsWith': false,
			'collection.filterNameLanguage': 'defaultFallbackEnglish',

			'collection.defaultHavesCollection': 'lastUsed',
			'collection.defaultWantsCollection': 'lastUsed',
			'collection.defaultPreset': 'lastUsed',
			'collection.autoMergeRows': true,

			'collection.priceDiffPeriod': '1M',
			'collection.pinDefaultCollection': false,
			'collection.autoSetTransactionalPrice': true,

			'collection.disableLanguage': false,
			'collection.disableEdition': false,
			'collection.disableCondition': false,
			'collection.disableNotes': false,
			'collection.disableLocation': false,
			'collection.disableOrigin': false,
			'collection.disableAltered': false,
			'collection.disableSigned': false,
			'collection.disableGradedCards': false,

			'collection.defaultQuantity': 1,
			'collection.defaultLanguage': 'en',
			'collection.defaultEdition': null,
			'collection.defaultCondition': 'NM',
			'collection.defaultAltered': false,
			'collection.defaultSigned': false,
			'collection.defaultGradingService': null,
			'collection.defaultGrade': null,
			'collection.defaultOrigin': null,
			'collection.defaultStorage': null,

			'collection.filterVisible': false,
			'collection.filterName': '',
			'collection.filterSet': null,
			'collection.filterSetCategory': null,
			'collection.filterRarity': null,
			'collection.filterCardTypes': null,
			'collection.filterLanguage': null,
			'collection.filterEdition': null,
			'collection.filterCondition': null,
			'collection.filterArchetype': null,
			'collection.filterDeck': null,
			'collection.filterOrigin': null,
			'collection.filterPrice': null,
			'collection.filterStorage': null,
			'collection.filterNotes': null,
			'collection.filterMergeable': null,
			'collection.filterHaves': null,
			'collection.filterWants': null,

			'collection.exportDelimiter': 'semicolon',
			'collection.defaultVisibility': 'public',

			// site
			'site.archetypeSortBy': 'cardType',
			'site.archetypeSortByDirection': 'ASC',
			'site.archetypeGroupBy': 'role',
			'site.archetypeView': 'tile',
			'site.cardSortBy': 'date',
			'site.cardSortByDirection': 'DESC',
			'site.cardGroupBy': null,
			'site.cardView': 'list',
			'site.setGroupBy': null,
			'site.setSortBy': 'cardNumber',
			'site.setSortByDirection': 'ASC',
			'site.setView': 'list',

			'site.alternateArtworksSortBy': 'name',
			'site.archetypesView': 'latest',
			'site.decksArchetype': null,
			'site.decksCards': null,
			'site.decksEvent': null,
			'site.decksExtraDeckSize': null,
			'site.decksFormat': null,
			'site.decksMainDeckSize': null,
			'site.decksSeason': null,
			'site.decksSortBy': null,
			'site.decksTags': null,
			'site.limitedListFilterChanges': false,
			'site.cardsOcgOnlyGroupBy': 'cardType',
			'site.cardsOcgOnlySortBy': 'name',
			'site.cardsOcgOnlyView': 'tile',
			'site.game': 'ygo',
			'site.searchSortBy': 'lastPrint',
			'site.searchView': 'list',
			'site.setsSortBy': 'releaseDesc',
			'site.targetsSortBy': 'name'
		}
	}
}

export const initialValueDeckSortAlgorithmMain = initialState.user.settings['app.deckSortAlgorithmMain']
export const initialValueDeckSortAlgorithmExtra = initialState.user.settings['app.deckSortAlgorithmExtra']

const persist = (state = initialState, action) => {
	switch (action.type) {
		case TOGGLE_MENU:
			if (state.menuCollapsed) {
				trackEvent(events.EXPAND_SIDEBAR)
			} else {
				trackEvent(events.COLLAPSE_SIDEBAR)
			}

			return {
				...state,
				menuCollapsed: !state.menuCollapsed
			}
		case TOGGLE_CARD_SEARCH:
			if (state.cardSearchCollapsed) {
				trackEvent(events.EXPAND_CARD_SEARCH)
			} else {
				trackEvent(events.COLLAPSE_CARD_SEARCH)
			}

			return {
				...state,
				cardSearchCollapsed: !state.cardSearchCollapsed
			}
		case SET_LANGUAGE:
			return {
				...state,
				language: action.language,
				languageMismatchHint: action.languageMismatchHint
			}
		case SET_CARD_NAME_LANGUAGE:
			return {
				...state,
				user: {
					...state.user,
					settings: {
						...state.user.settings,
						'app.cardNameLanguage': action.cardNameLanguage
					}
				}
			}
		case SET_USER:
			return {
				...state,
				authToken: action.authToken,
				user: {
					...action.user,
					settings: {
						...initialState.user.settings,
						...state.user.settings,
						...action.user.settings
					}
				}
			}
		case RESET_USER:
			return {
				...state,
				...initialState,
				language: state.language,
				user: {
					...initialState.user,
					settings: {
						...initialState.user.settings,
						'app.appearance': state.user.settings['app.appearance'],
						'app.cookiesAccepted': state.user.settings['app.cookiesAccepted']
					}
				}
			}
		case SET_USER_SETTING: {
			return {
				...state,
				user: {
					...state.user,
					settings: {
						...state.user.settings,
						[action.name]: action.value
					}
				}
			}
		}
		case SET_USER_SETTINGS: {
			return {
				...state,
				user: {
					...state.user,
					settings: {
						...state.user.settings,
						...action.settings
					}
				}
			}
		}
		case SET_UNREAD: {
			return {
				...state,
				unreadAdmin: action.data.unreadAdmin,
				unreadComments: action.data.unreadComments,
				rejectedComments: action.data.rejectedComments
			}
		}
		case SET_UNREAD_COMMENTS: {
			return {
				...state,
				unreadComments: action.unreadComments
			}
		}
		case SET_REJECTED_COMMENTS: {
			return {
				...state,
				rejectedComments: action.rejectedComments
			}
		}
		case SET_CARD_SEARCH:
			return {
				...state,
				search: {
					q: action.q,
					filter: action.filter,
					load: action.load,
					collection: action.collection,
					sortBy: action.sortBy || state.search.sortBy,
					sortOrder: action.sortOrder || state.search.sortOrder,
					view: action.view || state.search.view
				}
			}
		case SET_COUPON: {
			const { coupon } = action

			return {
				...state,
				coupon
			}
		}
		case SET_COLLECTIONS:
			return {
				...state,
				collections: action.collections
			}
		case APPLY_COLLECTION_UPDATES:
			const { updatedCollections } = action

			return {
				...state,
				collections: [
					...state.collections.filter(item => !updatedCollections.find(item2 => item2.slug === item.slug)),
					...updatedCollections
				].sort((a, b) => a.order - b.order)
			}
		case SET_COLLECTIONS_LOADING:
			return {
				...state,
				collectionsLoading: action.collectionsLoading
			}
		default:
			return state
	}
}

export default persist
