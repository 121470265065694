import cardLanguages from '../data/cardLanguages'
import { translationsMapping } from '../../config'
import formats from '../data/formats'
import { month } from './date'

export const isMonster = card => card.type === 'monster'
export const isSpell = card => card.type === 'spell'
export const isTrap = card => card.type === 'trap'
export const isSkill = card => card.type === 'skill'
export const isNormal = card => !!card.cardTypes.find(id => id === 1)
export const isXyz = card => !!card.cardTypes.find(id => id === 6)
export const isPendulum = card => !!card.cardTypes.find(id => id === 7)
export const isLink = card => !!card.cardTypes.find(id => id === 8)
export const isFusion = card => !!card.cardTypes.find(id => id === 4)
export const isSynchro = card => !!card.cardTypes.find(id => id === 5)
export const isToken = card => !!card.cardTypes.find(id => id === 24)
export const isTokenWithStats = (card, cardName) => card && isToken(card) && cardName && !cardName.includes('(') && !cardName.includes(')')
export const isExtraDeckCard = card => isLink(card) || isXyz(card) || isSynchro(card) || isFusion(card)

export const groupDeckCards = (cards, place) => {
	const columnCards = place ? cards.filter((deckCard) => {
		if (place === 'monster' || place === 'spell' || place === 'trap') {
			return deckCard.place === 'main' && deckCard.card.type === place
		}

		return deckCard.place === place
	}) : cards

	let countedCards = []

	columnCards.forEach((deckCard) => {
		const existing = countedCards.find(item => item.card.slug === deckCard.card.slug && item.place === deckCard.place && (!item.artworkId || !deckCard.artworkId || item.artworkId === deckCard.artworkId))

		if (existing) {
			countedCards = [
				...countedCards.filter(item => !(item.card.slug === deckCard.card.slug && (!item.artworkId || !deckCard.artworkId || item.artworkId === deckCard.artworkId))),
				{
					...existing,
					ownsCard: existing.ownsCard === true ? deckCard.ownsCard : false,
					ids: [
						...existing.ids,
						deckCard.id
					],
					checkedValues: [
						...existing.checkedValues,
						deckCard.checked
					]
				}
			]
		} else {
			countedCards.push({
				card: deckCard.card,
				ids: [deckCard.id],
				checkedValues: [deckCard.checked],
				artworkId: deckCard.artworkId,
				order: deckCard.order,
				place: deckCard.place,
				ownsCard: deckCard.ownsCard,
				price: deckCard.price
			})
		}
	})

	return countedCards
}

export const removeDuplicateCards = (prints, entity = 'card') => {
	const filtered = []

	return prints.filter((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!filtered.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& (!item.artwork || !print.artwork || item.artwork.id === print.artwork.id)
				&& (!item.artworkNumber || !print.artworkNumber || item.artworkNumber === print.artworkNumber)
				&& (!item.artworkId || !print.artworkId || item.artworkId === print.artworkId)
				&& (!item.language || !print.language || item.language.id === print.language.id)
		})) {
			filtered.push(print)

			return true
		}

		return false
	})
}

export const getOneItemPerCardNumber = (prints, entity = 'card') => {
	const result = []

	prints.forEach((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!result.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& item.cardNumber === print.cardNumber
		})) {
			result.push(print)
		}
	})

	return result
}

export const getOneItemPerCardNumberButKeepMultipleArtworks = (prints, entity = 'card') => {
	const result = []

	prints.forEach((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!result.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& item.cardNumber === print.cardNumber
				&& (!item.artwork || !print.artwork || item.artwork.id === print.artwork.id)
				&& (!item.artworkNumber || !print.artworkNumber || item.artworkNumber === print.artworkNumber)
		})) {
			result.push(print)
		}
	})

	return result
}

export const getOneItemPerCardNumberButKeepMultipleRarities = (prints, entity = 'card') => {
	const result = []

	prints.forEach((print) => {
		const printEntity = print[entity] ? print[entity] : print

		if (!result.find((item) => {
			const itemCard = item[entity] ? item[entity] : item

			return itemCard.slug === printEntity.slug
				&& item.cardNumber === print.cardNumber
				&& item.rarity === print.rarity
		})) {
			result.push(print)
		}
	})

	return result
}

export const createRegions = (cardPrints, setPrints) => {
	const prints = cardPrints || setPrints || []

	const plainRegions = Object.entries(prints ? prints.reduce((accumulator, print) => {
		if (print.language && print.language.language && !accumulator[print.language.language]) {
			accumulator[print.language.language] = prints.filter(data => (print.language && data.language && print.language.language === data.language.language) || (!print.language && !data.language))
		}

		return accumulator
	}, {}) : {})

	let regions = []
	const added = []

	plainRegions.forEach(([language, languagePrints], index) => {
		if (added.indexOf(language) !== -1) {
			return
		}

		const equals = [language]
		let mergedPrints = [...languagePrints]

		plainRegions.forEach(([language2, languagePrints2]) => {
			if (language !== language2 && arrayEquals(languagePrints, languagePrints2)) {
				equals.push(language2)
				added.push(language2)

				if (cardPrints) {
					mergedPrints = [
						...mergedPrints,
						...languagePrints2
					]
				}
			}
		})

		// merge ids
		if (setPrints) {
			equals.forEach((item) => {
				if (item !== language) {
					const equalLanguagePrints = plainRegions.find(([key]) => key === item)[1]

					if (mergedPrints.length === equalLanguagePrints.length) {
						mergedPrints = mergedPrints.map((item2, index) => ({
							...item2,
							ids: [
								...(item2.ids || [item2.id]),
								equalLanguagePrints[index].id
							],
							id: undefined
						}))
					}
				}
			})
		}

		regions.push({
			languages: equals,
			prints: mergedPrints
		})
	})

	if (regions.length > 0 && prints.find(item => !item.language)) {
		regions = [
			{
				languages: ['world'],
				prints: prints.filter(data => !data.language)
			},
			...regions
		]
	}

	return {
		regions,
		region: setPrints && regions.length > 0 ? regions[0] : null,
		regionIndex: setPrints && regions.length > 0 ? 0 : null,
	}
}

export const getRegionLabel = (item, userLanguage, slice = false) => {
	const data = slice && item.languages.length > 1 ? item.languages.slice(1) : item.languages
	const length = slice ? item.languages.length - 1 : item.languages.length

	return data.map((language, index) => {
		if (language === 'world') {
			return cardLanguages.world[`name_${translationsMapping[userLanguage]}`]
		}

		let str = slice && index === 0 ? ', ' : ''

		str += cardLanguages[language][`name_${translationsMapping[userLanguage]}`]

		if (index + 1 !== length) str += ', '

		return str
	})
}

export const arrayEquals = (arr1, arr2) => {
	let equal = true

	arr1.forEach((item) => {
		if (equal === false) return

		let found = false

		arr2.forEach((item2) => {
			if (
				(
					(item.card && item2.card && item.card.slug === item2.card.slug)
					|| (item.set && item2.set && item.set.slug === item2.set.slug)
				)
				&& item.rarity === item2.rarity
				&& item.cardNumber === item2.cardNumber
				&& item.artworkNumber === item2.artworkNumber
				&& item.release === item2.release
			) {
				found = true
			}
		})

		if (!found) {
			equal = false
		}
	})

	return equal
}

export const formatSlugToId = (slug) => {
	const format = Object.entries(formats).find(([key, value]) => value.slug === slug)

	if (format) {
		return Number(format[0])
	}

	return null
}

export const getSeasonName = season => season.startsOn ? month(season.startsOn) : season.name

export const getAllowed = (card, seasonId, traditional = false) => {
	if (!seasonId) {
		return 3
	}

	if (card.forbidden && card.forbidden.indexOf(seasonId) !== -1) {
		return traditional ? 1 : 0
	}

	if (card.limited && card.limited.indexOf(seasonId) !== -1) {
		return 1
	}

	if (card.semiLimited && card.semiLimited.indexOf(seasonId) !== -1) {
		return 2
	}

	return 3
}

export const getCardName = (deckCard, cardNameLanguage) => deckCard.card[`name_${cardNameLanguage}`] || deckCard.card.name || deckCard.card.name_en

export const getCardImage = (deckCard, cardNameLanguage) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork[`image_${cardNameLanguage}`] || artwork.image_en || artwork.image
	}

	return deckCard.card[`image_${cardNameLanguage}`] || deckCard.card.image_en || deckCard.card.image
}

export const getColor = (deckCard) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork.color
	}

	return deckCard.card.color
}

export const getArtwork = (deckCard) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork
	}

	return null
}

export const getArtworkNumber = (deckCard) => {
	const artwork = deckCard.card.alternativeArtworks ? deckCard.card.alternativeArtworks.find(item => item.id === deckCard.artworkId) : null

	if (deckCard.artworkId && artwork) {
		return artwork.number
	}

	return null
}
