const userTypes = [
	'player',
	'collector',
	'casual',
	'competitive',
	'masterduel',
	'duellinks',
	'goat'
]

export default userTypes
