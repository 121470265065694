import { matchPath } from 'react-router-dom'
import history from './history'
import { slugToCountryCode } from './string'

export const getPageLocation = (location) => {
	const background = location.state && location.state.background

	return background || location
}

export const FIXED_COLLAPSIBLE = {
	history: prefix => `${prefix}/history`
}

export const fixedCollapsibleIsVisible = location => !!Object.values(FIXED_COLLAPSIBLE).find(item => matchPath(location.pathname, {
	path: item('')
}))

export const hideOverlay = () => {
	if (history.location.state && history.location.state.background) {
		history.push(history.location.state.background.pathname, history.location.state.background.state)
	} else {
		history.goBack()
	}
}

export const getParam = (props, params, key) => {
	const index = params.findIndex(item => item === key)
	const data = params.map((param, index) => props.match.params[`param${index + 1}`])

	// page parameter is always the last param that is a valid number
	if (key === 'page') {
		return data.reverse().find(item => !Number.isNaN(Number(item))) || 1
	}

	// value by index (works only if all params are present in the URL)
	let value = data[index]

	// get next non-empty non-number value
	if (params.length !== data.filter(item => item !== null && item !== undefined && item !== '').length && !value) {
		let currentIndex = index

		while (currentIndex !== 0) {
			currentIndex -= 1

			if (data[currentIndex] && params[currentIndex] && (params.indexOf('country') === -1 || key === 'country' || !slugToCountryCode(data[currentIndex]))) {
				value = data[currentIndex]
				break
			}
		}
	}

	// country
	if (key === 'country') {
		return slugToCountryCode(value)
	}

	// presets values equals number of params, just return value by index
	if (Number.isNaN(Number(value)) && (params.indexOf('country') === -1 || !slugToCountryCode(value))) {
		return value
	}

	return null
}

export const getPage = (props) => {
	const urlParams = new URLSearchParams(props.location?.search)

	return Number(urlParams.get('page') || 1)
}
